
//import Tags from "./Tags.vue";
import Tools from "./Tools.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SideBar',
  components: {
    //Tags,
    //Categories,
    Tools
  },
  data() {
    return {
      
    }
  }
});
