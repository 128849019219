export class Link {
    constructor(
        text: string,
        link: string,
        target: string) {
            this.text = text;
            this.link = link;
            this.target = target;
        }

    public text: string;
    public link: string;
    public target: string;
}
