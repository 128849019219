<template>
<div id="replybox"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        showComments: Boolean,
        pageIdentifier: String
    },
    mounted() {
        if (this.showComments) {
            let commentScript = document.createElement('script');
            commentScript.setAttribute('src', 'https://cdn.getreplybox.com/js/embed.js');
            document.body.appendChild(commentScript);
            (window as any).replybox.identifier = this.pageIdentifier ?? document.title;
        }
    }
})
</script>

