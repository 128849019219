import { Image } from "./image";

export class Home {
    constructor (
        title: string,
        retrievePosts: boolean,
        notification: string,
        heroText: string,
        heroImage: Image) {
            this.title = title;
            this.heroText = heroText;
            this.heroImage = heroImage;
            this.notification = notification;
            this.retrievePosts = retrievePosts;
        }

    public title: string;
    public retrievePosts: boolean;
    public notification: string;
    public heroText: string;
    public heroImage: Image;
}