import home from '../views/home.vue'
import post from '../views/post.vue'
import defaultPage from '../views/default.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: home
  },
  {
    path: '/post/:slug',
    name: 'Post',
    component: post
  },
  {
    path: '/:page',
    name: 'Default',
    component: defaultPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;
