<template>
  <div>
    <div class="margin-40px-bottom">
      <a :href="post.url()">
        
      </a>
      
      <a :href="post.url()" v-if="post?.image">
        <img :src="post.image.url + '?width=800&height=420'" />
      </a>

      <div class="padding-30px-top padding-50px-lr xs-no-padding-lr">
        <span class="text-extra-dark-gray font-size14">
          <!-- <span class="font-weight-600">By:</span>
          <a href="@Model.Url" class="border-bottom">Max Swann</a> -->
        </span>
        <h5 class="margin-15px-top font-weight-600">
          <a :href="post.url()" class="text-extra-dark-gray">{{ post.title }}</a>
        </h5>
        <p v-html="post.description"></p>

        <div class="row" v-if="post.tags.length > 0">
          <div class="col">
            <a :href="'/?tag=' + tag" class="btn btn-sm btn-secondary" v-for="item in post?.links" :key="item.Link">
              <span class="badge bg-secondary">{{ item.replace('_', ' ') }}</span>
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <a :href="post.url()" class="border-bottom">
              {{ post.publishedDate() }}
            </a>
          </div>
          <div class="col-6">
            <a :href="post.url()">Read More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="en">
import { Post } from '@/models/post';
import { defineComponent } from 'vue';

export default defineComponent({
  props: { 
    post: Post
  },
  components: {
      
  }
})
</script>