
import { defineComponent } from "vue";
import { ReccommendedLinks } from "../models/reccommended-links";
import { HttpService } from "../services/httpService";

export default defineComponent({
  name: "Tools",
  data() {
    return {
      reccommended: ReccommendedLinks,
    };
  },
  methods: {
    async getReccommendedLinks() {
      let service: HttpService = new HttpService();
      this.reccommended = await service.getReccommended();
    },
  },
  mounted() {
    this.getReccommendedLinks();
  },
});
