
import { defineComponent } from 'vue';
import { Post } from '@/models/post';
import Comments from '@/components/Comments.vue';
import { HttpService } from '@/services/httpService';

export default defineComponent({
  name: 'Post',
  components: {
      Comments
  },
  data() {
      return {
        post: Post
      }
  },
  methods: {
    slug() {
      return this.$route.params.slug;
    },
    async getPost(): Promise<void> {
      this.post = await new HttpService().getPost(this.slug());
    }
  },
  mounted() {
    this.getPost();
  }
});
