export class Image {
    constructor (id: string,
        metaData: Record<string, string>
        ) {
            this.url = process.env?.VUE_APP_ASSETS_URI?.replace('{id}', id) ?? '';
            this.metaData = metaData;
            this.altText = this?.metaData?.altText ?? '';
        }

    public url: string;
    public altText: string;
    public metaData: Record<string, string>;
}