<template>
  <div class="side-bar padding-30px-left md-no-padding-left">
    <!-- <Categories /> -->
    <!-- <Tags /> -->
    <Tools />
  </div>
</template>

<script lang="ts">
//import Tags from "./Tags.vue";
import Tools from "./Tools.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SideBar',
  components: {
    //Tags,
    //Categories,
    Tools
  },
  data() {
    return {
      
    }
  }
});
</script>
