<template>
  <div class="main-wrapper">
    <NavBar />
    <!-- <div class="mt-2">@RenderSection("header", required: false)</div> -->

    <section>
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12 sm-margin-50px-bottom bg-gray">
            <router-view />
          </div>
          <div class="col-lg-4 col-md-12">
            <SideBar />
          </div>
        </div>
      </div>
    </section>
  </div>

  <Footer />

  <!-- <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div> -->
</template>

<script lang="en">
import { defineComponent } from 'vue';
import NavBar from './components/NavBar.vue';
import Footer from './components/Footer.vue';
import SideBar from "./components/SideBar.vue";

export default defineComponent({
  components: {
    SideBar,
    NavBar,
    Footer
  }
})
</script>
