<template>
  <div>
    <!-- <iframe class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/6HeN/JHR" width="100%"></iframe> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Categories',
  data() {
    return {
      
    }
  },
  mounted() {
    let newsletterScript = document.createElement('script');
    newsletterScript.setAttribute('src', 'https://app.mailjet.com/statics/js/iframeResizer.min.js');
    document.body.appendChild(newsletterScript);
  }
});
</script>
