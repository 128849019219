import { Image } from "./image";

export class Post {
    constructor (
        slug: string,
        body: string,
        title: string,
        published: string,
        tags: Array<string>,
        allowComments: boolean,
        description: string,
        image: Image | undefined 
        ) {
            this.slug = slug;
            this.body = body;
            this.tags = tags;
            this.title = title;
            this.published = new Date(published);
            this.allowComments = allowComments;
            this.description = description;
            this.image = image;
        }

    public slug: string;
    public body: string;
    public title: string;
    public published: Date;
    public tags: Array<string>;
    public allowComments: boolean;
    public description: string;
    public image: Image | undefined;

    public url(): string {
        return `/post/${this.slug}`;
    }

    public publishedDate(): string {        
        return `${this.published.toLocaleDateString()}`
    }
}