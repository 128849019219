import { Post } from "./post";

export class PostSearch {
    constructor (
        total: number,
        posts: Array<Post>) {
        this.total = total;
        this.posts = posts;
    }

    public total: number;
    public posts: Array<Post>;
}