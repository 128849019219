<template>
  <div v-if="reccommended">
    <div class="widget padding-30px-all md-padding-20px-all shadow-theme">
      <div class="widget-title margin-35px-bottom">
        <h3>{{ reccommended.title }}</h3>
      </div>
      <ul class="widget-list no-margin-bottom">
        <li v-for="item in reccommended.links" :key="item.Link">
          <a :href="item.link" :target="item.target">
            {{ item.text }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ReccommendedLinks } from "../models/reccommended-links";
import { HttpService } from "../services/httpService";

export default defineComponent({
  name: "Tools",
  data() {
    return {
      reccommended: ReccommendedLinks,
    };
  },
  methods: {
    async getReccommendedLinks() {
      let service: HttpService = new HttpService();
      this.reccommended = await service.getReccommended();
    },
  },
  mounted() {
    this.getReccommendedLinks();
  },
});
</script>
