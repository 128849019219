<template>
    <div v-html="page?.body"></div>
</template>

<script>
import { Page } from '@/models/page';
import { defineComponent } from 'vue';
import { HttpService } from '@/services/httpService';

export default defineComponent({
    name: 'default',
    data() {
        return {
            page: Page
        };
    },
    methods: {
        async getPage() {
            const pageIdentifier = this.$route?.params?.page;

            if (!pageIdentifier) {
                this.redirectHome();
            }
            
            this.page = await new HttpService().getPage(pageIdentifier);

            if (!this.page) {
                this.redirectHome();
            }
        },
        redirectHome() {
            this.$router.push('Home');
        }
    },
    mounted() {
        this.getPage();
    }
})
</script>
