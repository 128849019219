import { Link } from "./link";

export class ReccommendedLinks {
    constructor(
        title: string,
        links: Array<Link>) {
            this.title = title;
            this.links = links;
        }

    public title: string;
    public links: Array<Link>;
}
