export class Page {
    constructor (
        title: string,
        body: string,
        slug: string
    ) {
        this.title = title;
        this.body = body;
        this.slug = slug;
    }

    public title: string;
    public body: string;
    public slug: string;
}