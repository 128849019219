import axios from 'axios';
import { Home } from '@/models/home';
import { Link } from '@/models/link';
import { Post } from '@/models/post';
import { Image } from '@/models/image';
import { PostSearch } from '@/models/postSearch';
import { ReccommendedLinks } from '@/models/reccommended-links';
import { Page } from '@/models/page';

export class HttpService {
    public async getHome(): Promise<Home | any> {
        const data = {
            query: `{queryHomeContents{data{title{iv}heroImage{iv{url}}notification{iv}notification{iv}retrievePosts{iv}heroText{iv}}}}`,
            variables: null
        };

        const responseAsync = await this.postAsync(data);
        
        if (!responseAsync?.data) {
            return null;
        }

        const responseData = responseAsync.data.queryHomeContents[0]?.data;
        
        return new Home(responseData.title.iv, responseData.retrievePosts.iv, responseData.notification.iv, responseData.heroText.iv, responseData.heroImage.iv);
    }

    public async getReccommended(): Promise<ReccommendedLinks | any> {
        const data = {
            query: `{queryReccommendedLinksContents{data{title{iv},links{iv{text,link,target}}}}}`,
            variables: null
        };

        const responseAsync = await this.postAsync(data);
        
        if (!responseAsync?.data) {
            return null;
        }

        const responseData = responseAsync.data.queryReccommendedLinksContents[0].data;

        const links = new Array<Link>();

        responseData.links.iv.forEach((lnk: { text: string; link: string; target: string; }) => {
            links.push(new Link(lnk.text, lnk.link, lnk.target));
        });
        
        return new ReccommendedLinks(responseData.title.iv, links);
    }

    public async getPosts(take: number, page: number, searchTerm: string): Promise<PostSearch | any> {
        const skip = (page - 1) * take;

        const data = {
            query: `{queryPostsContentsWithTotal(top: ${take} skip: ${skip} filter: "${searchTerm}" orderby: "data/slug/iv"){total,items {data{heroimage{iv{id,metadata}},slug{iv},published{iv},allowComments{iv},title{iv},description{iv},tags{iv}}}}}`,
            variables: null
        };
        
        const responseAsync = await this.postAsync(data); 
  
        if (!responseAsync?.data) {
            return null;
        }
        
        const responseData = responseAsync.data.queryPostsContentsWithTotal;
        const posts: Array<Post> = new Array<Post>();

        responseData.items.forEach(function (item: any) {
            const post = item.data;
            const imageData = post.heroimage?.iv;
            let image;

            if (imageData) {
                image = new Image(imageData[0].id, imageData[0].metaData);
            }           

            posts.push(new Post(post.slug.iv, post.text?.iv ?? '', post.title.iv, post.published.iv, post.tags.iv, post.allowComments.iv, post.description?.iv, image));
        });

        return new PostSearch(responseData.total as number, posts);
    }

    public async getPost(slug: any): Promise<Post | any> {
        const data = {
          query: `{queryPostsContents(top: 1, filter: "data/slug/iv eq '${slug}'"){data{heroimage{iv{id, metadata}}title{iv},slug{iv},text{iv},published{iv},tags{iv},allowComments{iv}}}}`,
          variables: null
        };
        
        const responseAsync = await this.postAsync(data);
        if (!responseAsync?.data) {
            return null;
        }
        
        const post = responseAsync.data.queryPostsContents[0].data;
        const imageData = post?.heroimage?.iv;
        let image;

        if (imageData) {
            image = new Image(imageData[0].id, imageData[0].metadata);
        }

        const newPost = new Post(post.slug?.iv, post.text?.iv, post.title?.iv, post.published?.iv, post.tags?.iv, post.allowComments?.iv, post.description?.iv, image);
        return newPost;
    }

    public async getPage(page: any) {
        const data = {
            query: `{queryPagesContents(top: 1, filter: "data/slug/iv eq '${page}'"){data{title{iv},slug{iv},text{iv}}}}`,
            variables: null
        };
        
        const responseAsync = await this.postAsync(data);
        if (!responseAsync?.data) {
            return null;
        }
        
        const pageContent = responseAsync.data.queryPagesContents[0]?.data;

        if (!pageContent) {
            return;
        }

        const newPost = new Page(pageContent.title?.iv, pageContent.text?.iv, pageContent.slug?.iv);
        return newPost;
    }

    private async postAsync(data: any) : Promise<any> {
        let responseData: any;

        await axios.post(`${process.env.VUE_APP_CMS_URI}/content/maxswann-blog/graphql`, data)
          .then(res => {
            responseData = res.data;
          })
          .catch(error => {
              return;
          });
          
        return responseData;
    }
}
