<template>
  <div class="home">
    <div v-if="cards?.posts">
      <DisplayCard v-for="item in cards.posts" :key="item.slug" :post="item" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { PostSearch } from '@/models/postSearch';
import { HttpService } from '@/services/httpService';
import DisplayCard from '@/components/DisplayCard.vue';

export default defineComponent({
  name: 'Home',
  components: { 
    DisplayCard 
  },
  data() {
    return {
      cards: PostSearch
    }
  },
  methods: {
    async getHome(): Promise<void> {
      const home = await new HttpService().getHome();
      
      if (home) {
        if (home.retrievePosts) {
          this.getCards();
        }
      }
    },
    async getCards(): Promise<void> {
      let count = 10;
      let page = 1;

      if (!this.$route.params.count && Number.parseInt(this.$route.params.count) <= 20) {
        count = Number.parseInt(this.$route.params.count);
      }
      
      if (!this.$route.params.page && Number.parseInt(this.$route.params.page) <= 20) {
        page = Number.parseInt(this.$route.params.page);
      }
      
      this.cards = await new HttpService().getPosts(count, page, '');
    }
  },
  mounted() {
    this.getHome();
  }
});
</script>
