<template>
  <div class="navbar-default">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12">
          <div class="menu_area">
            <nav class="navbar navbar-expand-lg navbar-light no-padding">
              <div class="navbar-header navbar-header-custom">
                <a class="navbar-brand width-200px sm-width-180px xs-width-150px" href="/">
                  <img :src="assetUri()" alt="Logo Image" width="30" height="24" />
                  Max Swann
                </a>
              </div>
              <a class="nav-link" href="/contact">Contact</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    assetUri() {
      return process.env?.VUE_APP_ASSETS_URI?.replace('{id}', 'b3deaed6-4350-4000-aed2-d6824882ec6e?width=30&height=24') ?? '';
    }
  }
})
</script>