<template>
  <footer class="bg-light">
    <div class="padding-90px-top md-padding-70px-top sm-padding-50px-top">
      <div class="container">
        <div class="row">
          <div class="col-12 sm-margin-40px-bottom">
            <Newsletter />
          </div>
        </div>
        <div class="padding-30px-bottom margin-60px-top row">
          <div class="col-12">
            <div
              class="float-left xs-width-100 text-center xs-margin-5px-bottom"
            >
              <ul class="social-links no-margin">
                <li>
                  <a
                    class="ms-2 text-gray-500"
                    href="/contact"
                    title="Contact Us"
                  >
                    <i class="fad fa-file-contract"></i> Contact
                  </a>
                </li>
              </ul>
            </div>
            <div class="float-right xs-width-100 text-center">
              <p class="text-medium-gray margin-5px-top xs-no-margin-top">
                &copy; {{ new Date().getFullYear() }} Max Swann
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Newsletter from './newsletter.vue';

export default defineComponent({
    components: {
      Newsletter
    },
    data() {
        return {
            // isSubmitting: false,
            // email: '',
            // agreeToNewsletter: false,
            // errors: Array<string>(),
            // emailPattern: '^[a-zA-Z0-9.!#$%&’*+\\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$',
            // bannedEmailExstensions: Array<string>()
        }
    },
    methods: {
        // hcaptcha(): string | any {
        //     return document.querySelector('[data-hcaptcha-widget-id]')?.getAttribute("data-hcaptcha-response")?.toString();
        // },
        // formValid(): boolean {
        //     this.errors = new Array<string>();

        //     if (!this.email) {
        //         this.errors.push(`"${this.email}" Is Not A Valid Email`);
        //     }

        //     if (!this.agreeToNewsletter) {
        //         this.errors.push(`You Must Agree That We Are Able To Send You Emails`);
        //     }

        //     if (!this.hcaptcha()) {
        //         this.errors.push(`You Must Fill Out The Captcha Response In Order To Subscribe`);
        //     }

        //     return this.errors.length === 0;
        // },
        // async subscribe(): Promise<void> {
        //     event?.preventDefault();
        //     this.isSubmitting = true;

        //     if (this.formValid() !== true) {
        //         return;
        //     }

        //     const data = {
        //         email: this.email,
        //         hCaptchaResponse: this.hcaptcha(),
        //         confirmAllowEmailSubmission: this.agreeToNewsletter   
        //     }

        //     this.isSubmitting = false;
        // }
    },
    mounted() {
        // tbd
        // this.bannedEmailExstensions = new Array<string>('10minutemail.com', '1useMail.com', '20minutemail.com', 'altmails.com', 'anonymousemail.me', 'anonymouse.org', 'bulc.club', 'clipmails.com', 'crazymailing.com', 'cryptogmail.com', 'cyberatlantis.com');
    }
})
</script>

