<template>
  <div class="post">
    <h2>{{ post.title }}</h2>

    <img v-if="post?.image" :src="post.image?.url + '?width=1000&width=500'" :alt="post.image?.altText" />

    <div v-html="post.body" class="block"></div>
    <Comments v-if="post?.slug" :pageIdentifier="post.slug" :showComments="post.allowComments" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Post } from '@/models/post';
import Comments from '@/components/Comments.vue';
import { HttpService } from '@/services/httpService';

export default defineComponent({
  name: 'Post',
  components: {
      Comments
  },
  data() {
      return {
        post: Post
      }
  },
  methods: {
    slug() {
      return this.$route.params.slug;
    },
    async getPost(): Promise<void> {
      this.post = await new HttpService().getPost(this.slug());
    }
  },
  mounted() {
    this.getPost();
  }
});
</script>
